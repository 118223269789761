<template>
  <div id="Manage">
    <!-- 基础设置 卡券管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加分润配置</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchProfit(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @changeSwitch="changeSwitch"
                     @getPage="getPage">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click='clickDeleteData(scope.$index, scope.row)'>删除</span>
              </el-tooltip>

            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getProfitList, metProfitList, putStatusProfitListData
}
  from 'api/basis.js'
import {
  getAreaFirst,
  // getAreaCode
}
  from 'api/basisDate.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {

      searchData: '',//存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '添加分润配置信息',
        editName: '编辑分润配置信息',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        module_type: '',
        min_rate: '',
        max_rate: '',
        branch_office_rate: '',
        operation_center_rate: '',
        service_provider_rate: '',
        vip_rate: '',
        share_rate: ''
      },
      openFormLabel: [
        {
          model: 'module_type',
          label: '板块类型',
          type: 'select',
          options: [
            { value: 0, label: '旅游' },
            { value: 1, label: '酒店' },
            { value: 2, label: '商城' },
            { value: 3, label: '门票' }
          ]
        },
        {
          model: 'min_rate',
          label: '毛利率-最小值',
          tip: '%'
        },
        {
          model: 'max_rate',
          label: '毛利率-最大值',
          tip: '%'
        },
        {
          model: 'branch_office_rate',
          label: '分公司- 分润比率',
          tip: '%'
        },
        {
          model: 'operation_center_rate',
          label: '运营中心 - 分润比率',
          tip: '%'
        },
        {
          model: 'service_provider_rate',
          label: '服务商 - 分润比率',
          tip: '%'
        },
        {
          model: 'vip_rate',
          label: '付费会员 - 分润比率',
          tip: '%'
        },
        {
          model: 'share_rate',
          label: '分享赚 - 分润比率',
          tip: '%'
        },
      ],

      // form表单 搜索数据
      searchForm: {
        module_type: '',
        status: ''
      },
      searchformLabel: [
        {
          model: 'module_type',
          label: '板块类型',
          type: 'select',
          options: [
            { value: 0, label: '旅游' },
            { value: 1, label: '酒店' },
            { value: 2, label: '商城' },
            { value: 3, label: '门票' }
          ]
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          options: [
            { value: 0, label: '已启用' },
            { value: 1, label: '已禁止' }
          ]
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '分润ID',
        },
        {
          prop: 'module_type',
          label: '模块类型'
        },
        {
          prop: 'min_rate',
          label: '毛利率-最小值(%)'
        },
        {
          prop: 'max_rate',
          label: '毛利率-最大值(%)'
        },
        {
          prop: 'branch_office_rate',
          label: '分公司(分润%)'
        },
        {
          prop: 'operation_center_rate',
          label: '运营中心(分润%)'
        },
        {
          prop: 'service_provider_rate',
          label: '服务商(分润%)'
        },
        {
          prop: 'vip_rate',
          label: '付费会员(分润%)'
        },
        {
          prop: 'share_rate',
          label: '分享赚(分润%)'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'stateS',
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },


      // 表格-全部-数据
      cardAlldataObj: {},

      // id最大值
      MaxId: ''
    }
  },

  mounted () {
    this.getProfitList();

  },
  beforeUpdate () {
  },
  watch: {
    cardAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.cardAlldataObj.list,
        {

        },

      )
    }
  },

  methods: {

    //获得下列分类页的页数
    getPage () {
      this.getProfitList()
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getProfitList(this.searchData)
      } else {
        this.getProfitList()
      }
    },
    // 获取--分润列表数据
    getProfitList (data) {
      if (data) {
        getProfitList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.cardAlldataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getProfitList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.cardAlldataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }

    },
    //状态变更
    putStatusProfitListData (met, data) {
      putStatusProfitListData(met, data).then(res => {
        console.log(res)
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: DATA.message,
            type: 'error'
          })
        }
      })
    },
    //添加数据   --添加-编辑 景点
    metProfitList (met, data) {
      return new Promise(resolve => {
        metProfitList(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxID(this.tableData, 'id')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.isShowDialogVisible = true

    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      console.log(data)

      if (type == 'add') {
        const DATA = {
          id: data.id,
          module_type: data.module_type,
          min_rate: data.min_rate,
          max_rate: data.max_rate,
          branch_office_rate: data.branch_office_rate,
          operation_center_rate: data.operation_center_rate,
          service_provider_rate: data.service_provider_rate,
          vip_rate: data.vip_rate,
          share_rate: data.share_rate,

        }
        // console.log(DATA)
        this.metProfitList('POST', DATA)
        this.getProfitList()

      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          module_type: data.module_type,
          min_rate: data.min_rate,
          max_rate: data.max_rate,
          branch_office_rate: data.branch_office_rate,
          operation_center_rate: data.operation_center_rate,
          service_provider_rate: data.service_provider_rate,
          vip_rate: data.vip_rate,
          share_rate: data.share_rate,
        }
        this.metProfitList('PUT', DATA)
        this.getProfitList()
      }
      this.updataTableData()
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = this.$options.data().openForm;
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // 编辑操作
    async clickEditData (idx, row) {
      this.openForm = {
        id: row.id,
        module_type: row.module_type,
        min_rate: row.min_rate,
        max_rate: row.max_rate,
        branch_office_rate: row.branch_office_rate,
        operation_center_rate: row.operation_center_rate,
        service_provider_rate: row.service_provider_rate,
        vip_rate: row.vip_rate,
        share_rate: row.share_rate,
      }
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 删除操作
    async clickDeleteData (idx, row) {
      const DATA = {
        id: row.id
      }
      console.log(row.id)
      await this.metProfitList('DELETE', DATA)
      await this.updataTableData()
    },
    //刷新表格
    updataTableData () {
      this.getProfitList()
    },
    //搜索分润
    searchProfit (searchForm) {
      console.log(searchForm)
      const data = {
        module_type: searchForm.module_type,
        status: searchForm.status,
      }
      this.searchData = data
      this.getProfitList(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.module_type = '';
      searchForm.status = '';
      this.searchData = ''
      this.getProfitList()
    },
    //改变状态
    changeSwitch (row) {
      console.log(row)
      const DATA = {
        id: row.id,
        status: row.status
      }
      console.log(DATA)
      this.putStatusProfitListData('PUT', DATA)
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    width: 85%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
    position: relative;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-dialog {
    width: 30%;
  }
}
</style>
